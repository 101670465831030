<template>
  <div class="content-wrap">
    <div class="create-header">
      <div class="header-top">
        <span>{{ title }}</span>
        <div>（提示：此处填写的信息将用做开票时的销方信息，请仔细填写）</div>
      </div>
      <div class="header-bottom">
        <el-form ref="recordForm" :model="organization" :rules="ruless" label-width="140px">
          <el-row>
            <el-col :span="8">
              <el-form-item label="是否开票组织：" prop="isTaxpayer">
                <el-select v-model="organization.isTaxpayer" @change="changeNoYes">
                  <el-option label="否" value="N"></el-option>
                  <el-option label="是" value="Y"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="组织编码：" prop="uniqueCode">
                <el-input v-model.trim="organization.uniqueCode" maxlength="30"/>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="纳税人类型：" prop="taxpayerType">
                <el-select v-model="organization.taxpayerType">
                  <el-option label="请选择" value=""></el-option>
                  <el-option label="一般纳税人" value="GT"></el-option>
                  <el-option label="小规模纳税人" value="SS"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="上级组织：" prop="parentId">
                <organization-select :model="organization"
                                     all-label="请选择"
                                     org-id-prop="parentId"></organization-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="组织名称：" prop="name">
                <el-input v-model.trim="organization.name" maxlength="128"/>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="组织税号：" prop="taxNo">
                <el-input v-model.trim="organization.taxNo" maxlength="20"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="组织地址：" prop="address">
                <el-input v-model="organization.address"/>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="组织电话：" prop="phone">
                <el-input v-model="organization.phone"/>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="开户银行：" prop="bank">
                <el-input v-model="organization.bank" maxlength="128"/>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="8">
              <el-form-item label="银行账号：" prop="account">
                <el-input v-model="organization.account" maxlength="32"/>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="联系人：">
                <el-input v-model="organization.contacts"/>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="联系方式：">
                <el-input v-model="organization.contactsWay"/>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="8">
              <el-form-item label="数电试点企业：" prop="isElec">
                <el-select v-model="organization.isElec" placeholder="请选择">
                  <el-option
                      v-for="item in elecData"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <div class="create-main">
      <div class="header-top">
        <span>营业执照</span>
      </div>
      <div class="license-title">
        <img src="@/assets/icon/tips@2x.png"/>
        <span>有效期内三证合一的企业，请上传最新的营业执照。 格式要求：原件照片、扫描件或加盖公章的复印件，支持.jpg .jpeg .bmp .gif .png格式照片，大小不超过5M</span>
      </div>
      <div class="license-body">
        <div class="license-img">
          <el-upload
            action="#"
            :show-file-list="false"
            accept="image/jpeg,image/png,image/jpg"
            :on-change="handleUploadBusinessLicense">
            <img class="license-show" v-if="imageUrl!=null&&imageUrl!=''" :src="imageUrl">
            <img v-else src="@/assets/icon/upload@2x.png">
          </el-upload>
        </div>
        <el-button type="primary" @click="handleSubmitReview">提交审核</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import RegexLib from '@/assets/js/regex-lib'
import OrganizationSelect from '@/components/widgets/OrganizationSelect'
import {
  checkOrganization,
  checkOrganizationUniqueCode,
  ReqOrganizationGetSelectList,
  saveOrganization,
  selectOrganization
} from '@/service/organization'
import {getInfosByOrgId} from '@/service/paas/paas-service'

export default {
  name: 'SysDeptXz',
  components: { OrganizationSelect },
  data () {
    return {
      elecData: [
        {
          value: true,
          label: '是'
        },
        {
          value: false,
          label: '否'
        }
      ],
      tableData: [],
      chanelData: [
        {
          billingChannel: 'QD-RPA',
          billingChannelName: '全电-RPA',
          enabled: false,
          appId: '',
          appSecret: '',
          applyType: false
        },
        {
          billingChannel: 'QD-XSD',
          billingChannelName: '机器人X版',
          enabled: false,
          appId: '',
          appSecret: '',
          applyType: false
        },
        {
          billingChannel: 'QD-LQ',
          billingChannelName: '全电-乐企',
          enabled: false,
          appId: '',
          appSecret: '',
          applyType: false
        },
      ],
      options: [
        {
          value: false,
          label: '否'
        }, {
          value: true,
          label: '是'
        }
      ],
      title: '创建组织',
      isEdit: false,
      imageUrl: '',
      organization: {
        id: 0,
        parentId: 0,
        isTaxpayer: 'N',
        name: '',
        taxNo: '',
        uniqueCode: '',
        phone: '',
        address: '',
        contacts: '',
        bank: '',
        account: '',
        contactsWay: '',
        taxpayerType: '',
        isElec: false,
      },
      businessLicense: '',
      rules: {
        name: [
          { required: true, message: '请输入组织名称', trigger: 'blur' },
          { min: 1, max: 128, message: '长度在 1 到 128 个字符', trigger: 'blur' },
          { 'validator': this.handleCheckOrganizationName, trigger: 'blur' }
        ],
        taxNo: [
          { required: true, message: '请输入税号', trigger: 'blur' },
          { pattern: RegexLib.TaxNo, message: '请输入正确的税号', trigger: 'blur' },
          { 'validator': this.handleCheckOrganizationTaxNo, trigger: 'blur' }
        ],
        address: [
          { required: true, message: '请输入地址', trigger: 'blur' },
          { min: 1, max: 128, message: '请输入1到128个字符', trigger: 'blur' }
        ],
        phone: [
          { required: true, pattern: RegexLib.Telephone, message: '请输入正确电话', trigger: 'blur' },
        ],
        bank: [
          {required: true, message: '请输入开户银行', trigger: 'blur'}
        ],
        uniqueCode: [
          {required: true, message: '请输入组织编码!', trigger: 'blur'},
          {'validator': this.handleCheckOrganizationUniqueCode, trigger: 'blur'}
        ],
        account: [
          {required: true, message: '请输入银行账号', trigger: 'blur'}
        ],
        taxpayerType: [
          {required: true, message: '请选择纳税人类型', trigger: 'change'}
        ],
        isElec: [
          {required: true, message: '请选择是否是数电试点企业', trigger: 'blur'}
        ],

      },
      rules2: {
        name: [
          {required: true, message: '请输入组织名称', trigger: 'blur'},
          {min: 1, max: 128, message: '长度在 1 到 128 个字符', trigger: 'blur'},
          {'validator': this.handleCheckOrganizationName, trigger: 'blur'}
        ],
        uniqueCode: [
          {required: true, message: '请输入组织编码!', trigger: 'blur'},
          {'validator': this.handleCheckOrganizationUniqueCode, trigger: 'blur'}
        ],
      },
      rules3: {
        name: [
          { required: true, message: '请输入组织名称', trigger: 'blur' },
          { min: 1, max: 128, message: '长度在 1 到 128 个字符', trigger: 'blur' },
          { 'validator': this.handleCheckOrganizationName, trigger: 'blur' }
        ],
        taxNo: [
          { required: true, message: '请输入税号', trigger: 'blur' },
          { pattern: RegexLib.TaxNo, message: '请输入正确的税号', trigger: 'blur' },
          { 'validator': this.handleCheckOrganizationTaxNo, trigger: 'blur' }
        ],
        uniqueCode: [
          {required: true, message: '请输入组织编码!', trigger: 'blur'},
          {'validator': this.handleCheckOrganizationUniqueCode, trigger: 'blur'}
        ],
        taxpayerType: [
          {required: true, message: '请选择纳税人类型', trigger: 'change'}
        ],
        isElec: [
          {required: true, message: '请选择是否是数电试点企业', trigger: 'blur'}
        ],
      }
    }
  },
  created () {
    this.isEdit = this.$route.query.isEdit
    if (this.isEdit) {
      this.title = '编辑组织'
      let id = this.$route.query.id
      this.handleSelectOrganization(id)
      this.renderTable();
    } else {
      this.tableData = this.chanelData
    }
  },
  computed: {
    ruless: function () {
      if (this.organization.isTaxpayer === 'N') {
        return this.rules2
      } else {
        if(this.organization.isElec === false){
          return this.rules
        } else {
          return this.rules3
        }
      }
    }
  },
  methods: {
    async renderTable() {
      const {success, data} = await getInfosByOrgId(this.$route.query.id);
      if (success && data.length > 0) {
        this.tableData = data
      } else {
        this.tableData = this.chanelData
      }
    },
    changeNoYes() {
      this.$refs.recordForm.clearValidate()
    },
    // 获取组织详情
    handleSelectOrganization(id) {
      selectOrganization(id).then(res => {
        if (res.success) {
          console.log(res.data)
          this.organization = res.data
          this.imageUrl = res.data.fileUrl
        }
      })
    },
    // 禁止输入特殊字符
    handleNoInputSpecialCharacter (e) {
      let flag = RegexLib.TaxNo
      e.target.value = e.target.value.replace(flag, '')
    },
    // 组织编码唯一性校验
    handleCheckOrganizationUniqueCode (rule, value, callback) {
      checkOrganizationUniqueCode(this.organization).then(res => {
        if (res.success) {
          if (res.data) {
            callback(new Error('该组织编号已经存在'))
          } else {
            callback()
          }
        }
      })
    },
    // 组织税号校验
    handleCheckOrganizationTaxNo (rule, value, callback) {
      let param = {
        orgId: this.organization.id,
        taxNo: this.organization.taxNo
      }
      checkOrganization(param).then(res => {
        if (res.success) {
          if (res.data) {
            callback(new Error('该组织税号已经存在'))
          } else {
            callback()
          }
        }
      })
    },
    // 营业执照选择
    handleUploadBusinessLicense (file) {
      this.imageUrl = URL.createObjectURL(file.raw)
      this.businessLicense = file.raw
    },
    // 提交审核
    async handleSubmitReview () {
      this.$refs.recordForm.validate(async (valid) => {
        if (!valid) return

        //默认值
        if (!this.organization.isElec) {
          this.organization.isElec = false
        }
        let org = this.organization
        // 参数校验
        if (this.organization.isTaxpayer === 'Y') {
          let nameCheck = (org.name.length === 0)
          let taxNoCheck = (org.taxNo.length === 0)
          if (nameCheck || taxNoCheck) {
            this.toast('组织名称或者组织税号不能为空！', 'warning')
            return
          }
        } else {
          let nameCheck = (org.name.length === 0)
          if (nameCheck) {
            this.toast('组织名称不能为空！', 'warning')
            return
          }
        }

        let form = new FormData()
        form.append('id', org.id.toString())
        form.append('parentId', org.parentId.toString())
        form.append('name', org.name)
        form.append('uniqueCode', org.uniqueCode)
        if (this.organization.isTaxpayer === 'Y') {
          form.append('taxNo', org.taxNo)
        }
        form.append('phone', org.phone)
        form.append('address', org.address)
        form.append('contacts', org.contacts)
        form.append('contactsWay', org.contactsWay)
        form.append('bank', org.bank)
        form.append('account', org.account)
        form.append('isTaxpayer', org.isTaxpayer)
        form.append('taxpayerType', org.taxpayerType)
        form.append('isElec', org.isElec)
        if (this.businessLicense.length != 0) {
          form.append('businessLicense', this.businessLicense)
        }
        const { success, data } = await saveOrganization(form)
        if (success) {
          const orgRes = await ReqOrganizationGetSelectList()
          this.$store.commit('user/SET_ORGANIZATIONS', orgRes.data)
          this.$router.replace({ path: '/system/organization/success', query: { organization: data } })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .create-header {
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 2px 8px 0px rgba(153, 153, 153, 0.1);

    .header-top {
      padding: 16px 24px;
      font-size: 16px;
      font-weight: 500;
      border-bottom: 1px solid #e9e9e9;
      color: #333;
      display: flex;

      div {
        width: 406px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #F5222D;
        line-height: 20px;
        margin-left: 16px;
      }

      span:before {
        content: "|";
        display: inline-block;
        font-size: 16px;
        margin-right: 10px;
        background-color: #333;
      }
    }

    .header-bottom {
      margin-bottom: 24px;

      .el-row {
        margin: 24px 0px;

        &:last-child {
          padding-bottom: 20px;
        }
      }

      label {
        width: 70px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(102, 102, 102, 1);
        line-height: 20px;
      }

      span {
        width: 56px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
        line-height: 20px;
      }

    }
  }

  .create-main {
    width: 100%;
    height: 565px;
    background-color: #fff;
    overflow-x: auto;
    margin-bottom: 24px;

    .license-title {
      height: 40px;
      margin: 24px;
      background: rgba(235, 244, 255, 1);
      display: flex;
      align-items: center;
      padding-left: 24px;

      img {
        width: 16px;
        height: 16px;
        margin-right: 3px;
      }

      span {
        width: 1082px;
        height: 22px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 22px;
      }
    }

    .license-body {
      display: flex;
      flex-direction: column;
      align-items: center;

      .license-img {
        width: 180px;
        height: 240px;
        background: rgba(229, 229, 229, 1);
        margin-bottom: 48px;
        display: flex;
        justify-content: center;
        align-items: center;

        .license-show {
          width: 180px;
          height: 240px;
        }

        img {
          width: 58px;
          height: 58px;
        }
      }

      .el-button {
        width: 120px;
      }
    }
  }

  .header-top {
    padding: 16px 24px;
    font-size: 16px;
    font-weight: 500;
    border-bottom: 1px solid #e9e9e9;
    color: #333;

    span:before {
      content: "|";
      display: inline-block;
      font-size: 16px;
      margin-right: 10px;
      background-color: #333;
    }
  }
</style>
<style>
  .el-form-item__error {
    top: 30px;
  }
</style>
